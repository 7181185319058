import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";

interface Props {
  dataSet: any,
  formik: any,
  label: string,
  value: string
};

export function FormRadioGroup({
  dataSet,
  formik,
  label,
  value
}: Props) {
  return (
    <Box paddingBottom={2} sx={{ ".MuiFormControlLabel-label": { fontSize: "0.9rem" } }}>
      <FormControl>
        <Typography fontWeight="bold">{label}</Typography>
        <RadioGroup
          defaultValue="prefer-not-to-say"
          name="race-radio-buttons-group"
          onChange={(e) => formik.setFieldValue(value, e.target.value)}
          value={formik.values[value]}
        >
          {dataSet.map((x: any) => (
            <FormControlLabel
              key={x.value}
              value={x.value}
              control={<Radio size="small"/>}
              label={x.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
}