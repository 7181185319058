import styled from "@emotion/styled";

export const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
  paddingBottom: '56px',
  position: 'relative',

  '@media (min-width: 900px)': {
    paddingBottom: '32px'
  }
});

export const Image = styled.img({
  bottom: 0,
  height: '26px',
  left: '50%',
  position: 'absolute',
  transform: 'translateX(-50%)',

  '@media (min-width: 900px)': {
    left: 'calc(100% - 110px)',
    top: '40px'
  }
});