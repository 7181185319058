import config from "../config";

async function fetchWithRetry(url: string, options: RequestInit, retries = 1): Promise<Response> {
  for (let i = 0; i <= retries; i++) {
      const response = await fetch(url, options);
      if (response.status >= 500 && response.status < 600) {
          if (i === retries) {
              throw new Error(`HTTP error after ${retries + 1} tries! status: ${response.status}`);
          }
      } else {
          return response;
      }
  }
  throw new Error('fetchWithRetry failed without providing a response.');
}

export async function handleFetchIdealCandidate(idealCandidateUuid: string): Promise<any> {
  const url = `${config.coreApi.protocol}://${config.coreApi.host}/ideal-candidates/${idealCandidateUuid}`;
  const response = await fetchWithRetry(url, {
      method: 'GET'
  });
  if (!response.ok) {
      const msg = `Error fetching list of ideal candidates: ${response.status}`
      console.error(msg);
      throw new Error(msg);
  }
  return await response.json();
}

export async function handleSubmitCandidate(id: string, results: any): Promise<any> {
  const url = `${config.coreApi.protocol}://${config.coreApi.host}/ideal-candidates/${id}/matches`;
  const body = {
      candidate: {
          name: results.name,
          email: results.email,
          phone: results.phone
      },
      scorecard_results: [{
          scorecard_name: "skills",
          version: "1",
          criteria: Object.keys(results.skills).map((skillId: any) => ({
              id: skillId,
              value: results.skills[skillId].level.id,
              notes: results.skills[skillId].notes
          }))
      },{
          scorecard_name: "behaviors",
          version: "1",
          criteria: Object.keys(results.behaviors).map((behaviorId: any) => ({
              id: behaviorId,
              value: results.behaviors[behaviorId].level.id,
              notes: results.behaviors[behaviorId].notes
          }))
      }]
  };

  const response = await fetchWithRetry(url, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
  });
  if (!response.ok) {
      return response;
  }
}