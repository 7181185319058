import { IdealCandidateType } from "../types";

export const transformIdealCandidate = (ic: IdealCandidateType) => {
  return {
      id: ic.uuid,
      title: ic.title,
      theme: ic.theme,
      organizationName: ic.organizationName,
      organizationDescriptiveName: ic.organizationDescriptiveName,
      skills: ic.scorecards.find(scorecard => scorecard.name === 'skills')?.criteria.map(skill => {
          return {
              id: skill.id,
              name: skill.label,
              description: skill.description,
              weight: skill.weight,
              levels: skill.levels
          }
      }),
      behaviors: ic.scorecards.find(scorecard => scorecard.name === 'behaviors')?.criteria.map(behavior => {
          return {
              id: behavior.id,
              name: behavior.label,
              description: behavior.description,
              weight: behavior.weight,
              levels: behavior.levels
          }
      })
  }
};