import React from "react";
import { AppBar, Box } from "@mui/material";
import { Content } from "./styles";
import { IdealCandidateType } from "../../utils/types";

type Props = {
  color: string,
  idealCandidate: IdealCandidateType,
  logo?: string
};

export const Header = React.forwardRef<HTMLDivElement, Props>((props, ref) => (
  <Box ref={ref} width="100%" sx={{ backgroundColor: 'white', position: 'fixed', left: 0, top: 0, zIndex: 10 }}>
    <AppBar position="static" elevation={0} sx={{backgroundColor: props.color}}>
      <Content>
        {props.logo ? (
          <img src={props.logo} alt="Candidate Tools Logo" style={{height: '63px'}} />
        ) : (
          <h2>{props.idealCandidate?.organizationDescriptiveName}</h2>
        )}
      </Content>
    </AppBar>
    <Box maxWidth="952px" id="form-container" sx={{ margin: '0 auto', padding: '16px 8px' }}>
      <h2>{props.idealCandidate?.title} Candidate</h2>
    </Box>
  </Box>
));
