import { Box, Button, Checkbox, Divider, Grid, Typography } from "@mui/material";
import { Container, Image } from "./styles";
import { ATTESTATION_TEXT } from "../../utils/constants";

interface Props {
  attestation: boolean,
  handleValidateRequiredFields: () => boolean,
  setAttestation: (value: boolean) => void
};

export function Footer({
  attestation,
  handleValidateRequiredFields,
  setAttestation
}: Props) {
  return (
    <Container>
      <Divider />
      <Grid container>
        <Grid item sm={12} md={8}>
          <Box sx={{ alignItems: 'flex-start', display: 'flex', flexDirection: 'row' }}>
            <Checkbox
              checked={attestation}
              color="primary"
              onChange={(e) => setAttestation(e.target.checked)}
            />
            <Box sx={{ marginLeft: '8px', marginTop: '2px' }}>
              <Typography variant="body2">
                {ATTESTATION_TEXT}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Button
        disabled={!handleValidateRequiredFields()}
        type="submit"
        variant="contained"
      >
        Submit
      </Button>
      <Image src='/assets/ct_powered_by.png' alt="Powered By Candidate Tools Logo" />
    </Container>
  );
};
