import { Grid, Typography } from "@mui/material";
import { Content } from "./styles";

interface Props {
  children: any,
  sectionDescription: string,
  sectionHeader: string
};

export function Section({
  children,
  sectionDescription,
  sectionHeader
}: Props) {
  return (
    <Grid container spacing={2} sx={{ margin: '48px 0' }}>
      <Grid item xs={12} md={5}>
        <Typography mb={2} variant='h5'>{sectionHeader}</Typography>
        <Typography variant='body2'>{sectionDescription}</Typography>
      </Grid>
      <Grid item xs={12} md={3} />
      <Content>
        {children}
      </Content>
    </Grid>
  );
}