import styled from "@emotion/styled";

export const Content = styled.div({
  display: 'flex',
  flex: '1',
  flexDirection: 'column',
  gap: '16px',
  paddingTop: '32px',

  '@media (min-width: 900px)': {
    paddingTop: '0px'
  }
});