import styled from "@emotion/styled";

export const Container = styled.div<{ height: number | undefined }>(p => ({
  borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
  height: `${p.height && p.height + 16}px`,
  overflow: 'hidden',
  transition: 'all 350ms ease',

  '&:last-child': {
    borderBottom: 'none',
    height: `${p.height && p.height}px`,
  }
}))

export const Content = styled.div({
  border: '1px solid rgba(0, 0, 0, 0.23)',
  borderRadius: '4px',
  padding: '16px'
});