import { useRef, useState } from "react";
import { Box, Slider, Stack, Switch, Typography } from "@mui/material";
import { Container, Content } from "./styles";
import { FormField } from "../FormField";
import { getLevel } from "../../utils/helpers";
import { LevelsType } from "../../utils/types";

interface Props {
  dataLabel: string,
  dataSet: any,
  formik: any,
  handleChange: any,
  idealCandidate: any
}

export function Criteria({
  dataLabel,
  dataSet,
  formik,
  handleChange,
  idealCandidate
}: Props) {
  const fullRef = useRef<HTMLElement | null>(null);
  const halfRef = useRef<HTMLElement | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const findIndexByUUID = (uuid: string, levels: any) => uuid ? levels.findIndex((level: any) => level.id === uuid) : 0;

  function handleToggle(x: any) {
    setIsExpanded(!isExpanded);

    if (isExpanded) {
      handleChange(dataLabel, x.id, 'level', getLevel(idealCandidate, x.id, 0, dataLabel))
    }
  }

  return (
    <>
      {dataSet?.map((x: any) => (
        <Container key={x.id} height={isExpanded ? fullRef.current?.clientHeight : halfRef.current?.clientHeight}>
          <Box ref={fullRef}>
            <Box mb={2} ref={halfRef}>
              <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography variant='h6'>{x.name}</Typography>
                <Stack direction="row" alignItems="center">
                  <Typography variant="caption">No</Typography>
                  <Switch
                    checked={isExpanded}
                    onChange={() => handleToggle(x)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <Typography variant="caption">Yes</Typography>
                </Stack>
              </Box>
            </Box>
            <Content>
              <Box mb={4}>
                <Typography mb={3} variant="body2">{x.description}</Typography>
                <Box sx={{ padding: '0px 20px' }}>
                  <Slider
                    value={findIndexByUUID(formik.values[dataLabel][x.id]?.level?.id, x.levels)}
                    valueLabelDisplay="auto"
                    step={1}
                    marks={x.levels.map((level: LevelsType, index: number) => ({
                      value: index,
                      label: level.label,
                    }))}
                    min={0}
                    max={x.levels.length - 1}
                    onChange={(e: any, value: any) => handleChange(
                      dataLabel,
                      x.id,
                      'level',
                      getLevel(idealCandidate, x.id, value, dataLabel)
                    )}
                />
                </Box>
              </Box>
              <FormField
                formik={formik}
                handleChange={(e: any) => handleChange(
                  dataLabel,
                  x.id,
                  'notes',
                  e.target.value
                )}
                label="Tell Us More (Optional)"
                required={false}
                type="todo"
              />
            </Content>
          </Box>
        </Container>
      ))}
    </>
  );
};
