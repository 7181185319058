export const ATTESTATION_TEXT = "By checking this box, I hereby attest and confirm that the answers and information I have provided are true and accurate to the best of my knowledge.";

// Form sections --
export const DECISION_MAKING_DESCRIPTION = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.";
export const DECISION_MAKING_HEADER = "Decision Making";

export const GENERAL_INFO_DESCRIPTION = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.";
export const GENERAL_INFO_HEADER = "General Information";

export const SELF_INDENTIFICATION_DESCRIPTION = "We are an Affirmative Action, Equal Opportunity Employer. Our employment decisions are made without regard to race, color, religion, gender, national origin, age, disability, marital status, veteran or military status, or any other legally protected status. The purpose of this Employee EEO Self-Identification Form is to comply with federal government record-keeping and reporting requirements.";
export const SELF_INDENTIFICATION_HEADER = "Voluntary Self-Identification of Ethnicity, Race and Gender";

export const SKILLS_DESCRIPTION = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.";
export const SKILLS_HEADER = "Skills";