import { IdealCandidateType } from "../types";

export function getCriteria(idealCandidate: IdealCandidateType, label: string) {
  if (idealCandidate) {
    return idealCandidate[label]?.reduce((initialState: any, data: any) => {
      const level = data.levels[0];
      const notes = "";
      initialState[data.id] = {
          id: data.id,
          name: data.name,
          level,
          notes
      };
      return initialState;
    }, {});
  } else {
    return {};
  }
}