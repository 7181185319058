import { RefObject, useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { matchIsValidTel } from "mui-tel-input";
import { Divider, Box, Typography } from "@mui/material";

import { FormValueType } from "../utils/types";
import { Criteria, Footer, FormField, FormRadioGroup, Header, Section } from "../components";
import { DECISION_MAKING_DESCRIPTION, DECISION_MAKING_HEADER, GENDER_VALUES, GENERAL_INFO_DESCRIPTION, GENERAL_INFO_HEADER, RACE_VALUES, SELF_INDENTIFICATION_DESCRIPTION, SELF_INDENTIFICATION_HEADER, SKILLS_DESCRIPTION, SKILLS_HEADER } from "../utils/constants";
import { getCriteria, transformIdealCandidate } from "../utils/helpers";
import { handleFetchIdealCandidate, handleSubmitCandidate } from "../api";

const OuterContainerStyle = {
  margin: '0 auto',
  maxWidth: '1000px',
  paddingBottom: '32px',
  paddingLeft: '32px',
  paddingRight: '32px',
  position: 'relative'
};

export function PotentialCandidateForm() {
  const headerRef: RefObject<HTMLDivElement> = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [attestation, setAttestation] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const idealCandidateUuid= queryParams.get('ic');
  const [idealCandidate, setIdealCandidate] = useState<any>(null);

  useEffect(() => {
    const fetchIdealCandidate = async () => {
      if (!idealCandidate && idealCandidateUuid) {
          const result = await handleFetchIdealCandidate(idealCandidateUuid);
          setIdealCandidate(transformIdealCandidate(result));
      }
    };
  
    fetchIdealCandidate();
  }, [idealCandidateUuid, idealCandidate])

  useEffect(() => {
    if (headerRef.current) {
      const height = headerRef.current.offsetHeight;
      setHeaderHeight(height);
    }
  }, [])

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      gender: "prefer-not-to-say",
      phone: "",
      race: "prefer-not-to-say",
      skills: getCriteria(idealCandidate, 'skills'),
      behaviors: getCriteria(idealCandidate, 'behaviors')
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      email: Yup.string().email('Must be a valid email address').matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/,'Must be a valid email address').required('Email is required')
    }),
    onSubmit: async (values) => {
      await handleSubmitCandidate(idealCandidate?.id, { ...values, email: values.email.toLowerCase() });
      setSubmitted(true);
    }
  });

  const handleChange = (dataSet: keyof FormValueType, id: string, label: string, value: string) => {
    const data = formik.values[dataSet] as any;
    formik.setFieldValue(dataSet, {
      ...data,
      [id]: { ...data[id], [label]: value }
    })
  };

  function handlePhoneBlur() {
    if (!formik.touched.phone) formik.setTouched({ ...formik.touched, phone: true });

    if (!formik.values.phone || !matchIsValidTel(formik.values.phone)) {
      formik.setFieldError('phone', 'Phone is required')
    } else {
      const { phone, ...rest } = formik.errors;
      // @ts-ignore
      formik.setFieldError({ ...rest });
    }
  }

  function handlePhoneChange(value: string) {
    if (!formik.touched.phone) formik.setTouched({ ...formik.touched, phone: true });

    formik.setFieldValue('phone', value);

    const { phone, ...rest } = formik.errors;
    // @ts-ignore
    formik.setFieldError({ ...rest });
  };

  function handleValidateRequiredFields() {
    const requiredFields: (keyof FormValueType)[] = ['name', 'email', 'phone'];
    return attestation && requiredFields.every(field => formik.touched[field] && !formik.errors[field]);
  };

  return (
    <Box sx={{ ...OuterContainerStyle, paddingTop: `${headerHeight - 8}px` }}>
      <Header ref={headerRef} color={idealCandidate?.theme?.applicationHeaderColor || "#186BCC"} idealCandidate={idealCandidate} />

      {submitted ? (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box pl={2} pr={2} pt={10}>
            <Typography component="h2" variant="h2">Application submitted!</Typography>
            <Typography sx={{ textAlign: 'center' }}>You may close this browser window.</Typography>
          </Box>
        </Box>
      ) : (
        <form onSubmit={formik.handleSubmit}>

          {/* General info section */}
          <Section sectionHeader={GENERAL_INFO_HEADER} sectionDescription={GENERAL_INFO_DESCRIPTION}>
            <FormField formik={formik} label='Name' type='name' />
            <FormField formik={formik} label='Email' type='email' />
            <FormField formik={formik} handlePhoneBlur={handlePhoneBlur} handlePhoneChange={handlePhoneChange} label='Phone Number' telephone type='phone' />
          </Section>

          <Divider />

          {/* Skills section */}
          <Section sectionHeader={SKILLS_HEADER} sectionDescription={SKILLS_DESCRIPTION}>
            <Criteria dataLabel='skills' dataSet={idealCandidate?.skills} formik={formik} handleChange={handleChange} idealCandidate={idealCandidate} />
          </Section>

          <Divider />

          {/* Behaviors section */}
          <Section sectionHeader={DECISION_MAKING_HEADER} sectionDescription={DECISION_MAKING_DESCRIPTION}>
            <Criteria dataLabel='behaviors' dataSet={idealCandidate?.behaviors} formik={formik} handleChange={handleChange} idealCandidate={idealCandidate} />
          </Section>

          <Divider />

          {/* Voluntary identification section */}
          <Section sectionHeader={SELF_INDENTIFICATION_HEADER} sectionDescription={SELF_INDENTIFICATION_DESCRIPTION}>
            <FormRadioGroup dataSet={GENDER_VALUES} formik={formik} label="Gender" value="gender" />
            <FormRadioGroup dataSet={RACE_VALUES} formik={formik} label="Race" value="race" />
          </Section>

          <Footer attestation={attestation} handleValidateRequiredFields={handleValidateRequiredFields} setAttestation={setAttestation} />
        </form>
      )}
    </Box>
  );
}
