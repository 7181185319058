import { FocusEventHandler } from "react";
import { TextField, Typography } from "@mui/material";
import { MuiTelInput, MuiTelInputInfo } from 'mui-tel-input';
import { Container } from './styles';

interface Props {
  formik: any,
  handleChange?: (e: Event) => void,
  handlePhoneBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  handlePhoneChange?: (value: string, info: MuiTelInputInfo) => void,
  label: string,
  required?: boolean,
  telephone?: boolean,
  type: string
};

export function FormField({
  formik,
  handleChange,
  handlePhoneBlur,
  handlePhoneChange,
  label,
  required = true,
  telephone = false,
  type
}: Props) {
  return (
    <Container>
      <Typography variant="caption">{label}</Typography>
      {telephone ? (
        <MuiTelInput
          defaultCountry="US"
          required
          size="small"
          value={formik.values.phone}
          onChange={handlePhoneChange}
          onBlur={handlePhoneBlur}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
        />
      ) : (
        <TextField
          type={type}
          fullWidth
          required={required}
          size="small"
          name={type}
          onChange={handleChange || formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched[type] && Boolean(formik.errors[type])}
          helperText={formik.touched[type] && formik.errors[type]}
          value={formik.values[type]}
        />
      )}
    </Container>
  );
};
